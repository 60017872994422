@use '../../styles/mixins.scss' as *;
@use '../../styles/variables.scss' as *;

.canadian-quality {
    text-align: center;
    padding: 3rem 5%;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);

    &__title {
        font-size: 2rem;
        font-weight: 700;
        color: $accent-blue;
        margin-bottom: 2rem;

        &--highlight {
            color: #d32f2f; // Red color for "Canadian"
        }
    }

    &__leaf {
        color: #d32f2f; // Red maple leaf icon
        margin-left: 5px;
        font-size: 1.5rem;
    }

    &__grid {
        display: flex;
        flex-wrap: wrap;
        gap: 1.5rem;
        justify-content: center;
    }

    &__tile {
        background: white;
        padding: 1.5rem;
        border-radius: 8px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        width: 100%;
        max-width: 350px;
        transition: all 0.3s ease-in-out;
        text-align: center;

        &:hover {
            transform: translateY(-5px);
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
        }

        h4 {
            font-size: 1.4rem;
            color: $accent-blue;
            margin-bottom: 0.5rem;
        }

        p {
            font-size: 1.1rem;
            line-height: 1.6;
            color: #333;
        }
    }

    &__icon {
        font-size: 2.5rem;
        color: $accent-blue;
        margin-bottom: 1rem;
    }
}

/* 📱 Mobile Layout: Stacked */
@include tablet {
    .canadian-quality {
        &__grid {
            flex-wrap: nowrap;
            align-items: center;
        }

        &__tile {
            width: 80%;
        }
    }
}

/* 🖥️ Desktop Layout: Horizontal Tiles */
@include desktop {
    .canadian-quality {
        &__grid {
            flex-wrap: nowrap; // Ensures all tiles stay in one line
            justify-content: center;
            gap: 2rem;
        }

        &__tile {
            width: 30%; // Ensures three tiles are aligned in a row
            max-width: 350px;
        }
    }
}
