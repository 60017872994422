@use '../../styles/typography' as *;
@use '../../styles/mixins.scss' as *;
@use '../../styles/variables.scss' as *;

.quality-build {
    max-width: 100vw;
    margin: 0 auto;
    padding: 2rem 5%;

    &__header {
        background-color: #000;
        padding: 1.5rem 0;
        text-align: center;

        &--title {
            color: #fff;
            font-size: 2rem;
            text-transform: uppercase;
            letter-spacing: 2px;
            text-decoration: underline 2px $accent-yellow;
            transition: all 0.3s ease;

            &:hover {
                color: $accent-yellow;
                cursor: pointer;
            }
        }
    }

    .materials {
        &__gunite,
        &__plumbing,
        &__liner,
        &__experience,
        &__under-liner-steps {
            padding: 2.5rem;
            border-radius: 8px;
            background: rgba(255, 255, 255, 0.9);
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
            text-align: center;
            margin-bottom: 3rem;

            &--title {
                font-size: 2rem;
                font-weight: bold;
                margin-bottom: 1.5rem;
                color: $accent-blue;
            }

            &--subtitle {
                font-size: 1.5rem;
                font-weight: 600;
                margin-bottom: 1rem;
            }

            &--about {
                text-align: center;
                font-size: 1.2rem;
                line-height: 1.75;
                max-width: 900px;
                margin: 1rem auto;
            }

            &--container {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 2rem;
                margin-top: 1rem;
            }

            &--img,
            &--video {
                width: 100%;
                height: auto;
                max-width: 800px;
                border-radius: 10px;
                box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
                margin-bottom: 1.5rem;
            }
        }

        &__under-liner-steps--carousel {
            width: 100%;
            max-width: 900px;
            margin: 2rem auto;

            .carousel .slide {
                background: none;
            }

            .carousel .control-arrow {
                opacity: 0.5;
                transition: opacity 0.3s ease-in-out;

                &:hover {
                    opacity: 1;
                }
            }

            .carousel .control-dots .dot {
                background: #000;
                box-shadow: none;
            }

            .carousel .control-dots .dot.selected {
                background: $accent-yellow;
            }

            .carousel .legend {
                background: rgba(0, 0, 0, 0.6);
                color: #fff;
                font-size: 1rem;
                padding: 0.5rem;
                border-radius: 8px;
            }
        }
    }
}

/* Liner Slideshow Enhancements */
.materials__under-liner-steps--carousel {
    width: 100%;
    max-width: 900px;
    margin: 2rem auto;
    position: relative;

    /* Adjust arrow sizes & visibility */
    .carousel .control-arrow {
        width: 50px; /* Larger clickable area */
        height: 50px;
        background: rgba(0, 0, 0, 0.6); /* Darker for visibility */
        border-radius: 50%;
        transition: all 0.3s ease-in-out;
        opacity: 1; /* Ensure always visible */

        &:hover {
            background: rgba(0, 0, 0, 0.8);
        }
    }

    /* Make the arrow icons bigger */
    .carousel .control-arrow::before {
        font-size: 2rem;
        color: white;
    }

    /* Style the dot indicators */
    .carousel .control-dots {
        bottom: -20px; /* Move dots up slightly */
    }

    .carousel .control-dots .dot {
        width: 12px;
        height: 12px;
        background: rgba(255, 255, 255, 0.7);
        border: 2px solid $accent-blue; /* Highlighted with brand color */
        transition: all 0.3s ease;

        &.selected {
            background: $accent-yellow; /* Stand out color */
            border-color: white;
            transform: scale(1.2);
        }
    }

    /* Swipe hint for mobile */
    @media (max-width: 768px) {
        &::after {
            content: "⬅️ Swipe to See More ➡️";
            position: absolute;
            bottom: -30px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 1rem;
            color: $accent-blue;
            font-weight: bold;
            animation: fadeInOut 2.5s infinite;
        }
    }

    /* Swipe indicator animation */
    @keyframes fadeInOut {
        0%, 100% { opacity: 0.7; }
        50% { opacity: 1; }
    }
}


/* 📱 Mobile (Stacked Layout) */
@include tablet {
    .quality-build {
        &__header {
            padding: 2rem 0;

            &--title {
                font-size: 2.5rem;
            }
        }

        .materials {
            &__gunite,
            &__plumbing,
            &__liner,
            &__experience,
            &__under-liner-steps {
                padding: 2rem;
                align-items: center;
                text-align: center;

                &--img,
                &--video {
                    width: 100%;
                    height: auto;
                    margin-bottom: 1rem;
                }

                &--about {
                    font-size: 1.2rem;
                    line-height: 1.8;
                    margin: 1rem 4rem;
                }

                &--title {
                    font-size: 1.75rem;
                    margin-bottom: 1rem;
                }

                &--container {
                    flex-direction: column;
                    justify-content: center;
                    width: 100%;
                }
            }
        }
    }
}

/* 🖥️ Desktop (Side-by-Side Layout) */
@include desktop {
    .quality-build {
        &__header {
            padding: 3rem 0;

            &--title {
                font-size: 3rem;
            }
        }

        .materials {
            &__gunite,
            &__plumbing,
            &__liner,
            &__experience,
            &__under-liner-steps {
                display: flex;
                flex-direction: column;
                align-items: center;

                &--container {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-evenly;
                    flex-wrap: wrap;
                    gap: 3rem;
                    max-width: 1200px;
                }

                &--img,
                &--video {
                    max-width: 50%;
                }
            }

            &__under-liner-steps--carousel {
                max-width: 1000px;
            }
        }
    }
}
