@use '../../styles/typography' as *;
@use '../../styles/mixins.scss' as *;
@use '../../styles/variables.scss' as *;

.excavation {
    box-sizing: border-box;
    max-width: 100vw;
    margin: 0 auto;
    padding: 2rem 5%;

    &__header {
        background-color: $accent-blue;
        padding: 2rem;
        text-align: center;

        &--title {
            color: #fff;
            font-size: 2.5rem;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
    }

    &__video {
        display: flex;
        justify-content: center;
        padding: 2rem 0;

        &--video {
            width: 90%;
            max-width: 800px;
            border-radius: 10px;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
        }
    }

    &__protection,
    &__access {
        box-sizing: border-box;
        margin: 3rem auto;
        padding: 2rem;
        background-color: #f8f9fa;
        border-radius: 10px;
        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
        max-width: 1200px;
        text-align: center;

        &--header {
            width: 100%;
            text-align: center;
            margin-bottom: 2rem;
        }

        &--title {
            font-size: 2rem;
            color: $accent-blue;
            font-weight: bold;
        }

        &--gallery {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 2rem;
            width: 100%;
            max-width: 1200px;
        }
    }

    .protection__tile,
    .access__tile {
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
        padding: 1.5rem;
        text-align: center;
        transition: transform 0.3s ease-in-out;
        width: 100%;

        &:hover {
            transform: translateY(-5px);
        }

        &--title {
            font-size: 1.5rem;
            margin-bottom: 1rem;
            color: $accent-blue;
            font-weight: 600;
        }

        &--img,
        &--video {
            width: 100%;
            max-width: 400px;
            height: auto;
            border-radius: 5px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        }

        &--description {
            font-size: 1rem;
            line-height: 1.6;
            margin-top: 1rem;
        }

        &--list {
            list-style: none;
            padding: 0;
            margin-top: 1rem;
            font-size: 1rem;
        }

        &--item {
            padding: 0.5rem;
            font-weight: 500;
        }
    }
}

// Responsive Layout Adjustments
@include tablet {
    .excavation {
        &__protection,
        &__access {
            &--gallery {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                gap: 2rem;
            }

            .protection__tile,
            .access__tile {
                width: 48%; // Two tiles per row
                min-width: 300px;
            }
        }
    }
}

// Lowered desktop breakpoint to 1024px for laptops
@include desktop {
    @media (min-width: 1024px) {
        .excavation {
            &__protection,
            &__access {
                &--gallery {
                    display: flex;
                    justify-content: center;
                    gap: 2rem;
                }

                .protection__tile,
                .access__tile {
                    width: 30%; // Three tiles per row
                    min-width: 300px;
                }
            }
        }
    }
}
